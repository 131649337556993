body
  max-width: 100% !important
  background-color: #ffc200
  font-family: "Noto Sans SemiCondensed Black", sans-serif

.global
  user-select: none

$menu-height: 30px

.menu
  //background: black
  background-color: #ffc200
  height: $menu-height

$padding: 10px
//$menu-fonts-size: 18px
.menu-link
  cursor: pointer
  user-select: none
  text-decoration: none
  //max-font-size: $menu-fonts-size
  //font-size: $menu-fonts-size
  color: red

.selected-menu-item
  color: white

.menu-link:hover
  transition: 0.3s
  color: white

.menu-link-text
  //background: blueviolet
  float: left
  margin-left: 10px
  //height: 100%
  font-size: 21px

.menu-link-icon
  font-size: 24px
  float: right
  margin-right: 10px
//height: 100%