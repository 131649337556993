.center-buttons
  display: flex
  flex-direction: column
  align-items: center
  //justify-content: center
  height: 100vh
  min-width: 372px

.button-pompe
  max-width: 372px
  width: 90%
  height: 50px
  margin-bottom: 20px

.duration-input
  max-width: 372px
  width: 90%

