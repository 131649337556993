

$header-height: 80px
.header
  z-index: -1
  height: $header-height
  background-color: #e4001a
  position: relative
  //font-size: 30px

$euss: 66%
.header-title
  font-size: 80px
  //color: black
  //font-size: 100px
  //margin: 0
  position: absolute
  top: $euss
  -ms-transform: translateY(-$euss)
  transform: translateY(-$euss)


$layout-height: calc(100vh - $header-height - 30px)
.container-layout
  background-color: #ffc200 !important
  width: 100%
  height: $layout-height

//.poster-container
//  max-width: 400px
//  max-height: 600px

.left-col
  background-color: red
  width: 200px

.right-col
  width: 200px

.form-label
  width: 60%
  //max-width: 200px
  //width: 300px
  display: inline-block

.form-field
  width: 40%
  //max-width: 70%
  height: 30px
  border-width: 1px
  border-radius: 3px
  //border-color: black
  //padding: 0px
  box-sizing: border-box

.clickable-pointer
  cursor: pointer

//.form-row
//  //background: green
//  //height: 40px
//  //background: #00fff4
//  margin-bottom: 5px
//  width: 100%
//
.form-poster-field
  margin-bottom: 5px
  width: 100%

.poster-select
  //background: blue
  margin: auto

.bouton-telecharger
  margin: auto
  width: 100%
  //width: 600px
  height: 40px

.container-form-preview
  width: 100%
  //height: 95%
  height: $layout-height
  //background: aquamarine
  // A REMETTRE
  margin: auto
  display: flex
  justify-content: center
  flex-wrap: wrap
  gap: 10px

//align-items: center

//.container-gneu
//  display: flex
//  justify-content: center
//  //align-items: center
//  background: #888888

.poster-view
  width: 100%
  //height: 600px
  //max-width: 100%
  //max-height: 600px

.haut-ahi
  min-height: 90%
  max-height: 90%
  //background: #e4001a

.bas-ahi
  //margin: auto
  //display: flex
  justify-content: center
  flex-wrap: wrap
  gap: 10px
  height: 10%
  //min-height: 10%
  //background: blue

.deux-boutons
  display: grid
  grid-template-columns: repeat(2, 50%)
  gap: 10px

.container-form
  width: 50%
  max-width: 466px
  min-width: 372px

.container-preview
  width: 50%
  min-height: $layout-height
  max-width: 466px
  min-width: 372px
  //margin: auto

.container-galerie
  width: 100%
  max-width: 466px
  min-width: 372px
  height: 100%

//.container-poster
//  width: 90%
//  height: 90%
//  margin: auto
  //overflow: hidden

.react-pdf__Page__canvas
  //margin: auto
  overflow: scroll
  height: 100% !important
  width: 100% !important
  max-width: 100% !important
  max-height: 100% !important

//.react-pdf__Page__textContent.textLayer
//  height: 100% !important
//  width: 100% !important
//  max-width: 100% !important
//  max-height: 100% !important

//.react-pdf__Page
//  max-width: 100% !important
//  max-height: 100% !important
//  height: 100% !important
//  width: 100% !important

.arrows
  margin: auto
  width: 60px

.bouton-arrow
  cursor: pointer
  font-size: 30px

.bouton-arrow:hover
  color: gray

.flag-container
  cursor: pointer
  border: black
  height: 40px

.flag
  //position: absolute
  //display: inline-block
  //display: inline-flex
  //width: 100px
  height: 40px
  width: 60px


.flags
  display: flex
  //width: 250px
  height: 80px
  //background: #00fff4
  float: right